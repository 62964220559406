

@mixin border_radius($value) {
  -webkit-border-radius: $value;
     -moz-border-radius: $value;
      -ms-border-radius: $value;
          border-radius: $value;
}

@mixin box_shadow ($value) {
       -moz-box-shadow: $value;
	-webkit-box-shadow: $value; 
	        box-shadow: $value;
}

@mixin box_sizing ($value) {
    -webkit-box-sizing: border-box;
       -moz-box-sizing: border-box;
            box-sizing: border-box;
}


@mixin transition_mixin ($value) {
	-webkit-transition: $value;
	   -moz-transition: $value;
	     -o-transition: $value;
	        transition: $value;
}

@mixin perspective_mixin ($value) {
	        perspective: $value;
	-webkit-perspective: $value;
	   -moz-perspective: $value;
	    -ms-perspective: $value;
	     -o-perspective: $value;
}

@mixin perspective_origin_mixin ($value) {
	        perspective-origin: $value;
	-webkit-perspective-origin: $value;
	   -moz-perspective-origin: $value;
	     -moz-transform-origin: $value;
	    -ms-perspective-origin: $value;
	     -o-perspective-origin: $value;
}

@mixin transform_mixin ($value) {
	        transform: $value;
	   -moz-transform: $value;
	-webkit-transform: $value;
}


@mixin animation_mixin ($value) {
	        animation: $value;
	-webkit-animation: $value;
	   -moz-animation: $value;
	     -o-animation: $value;
}


@mixin transform ($value) {
    -moz-transform: scale($value);
 -webkit-transform: scale($value);
	 -ms-transform: scale($value);
	  -o-transform: scale($value);
		 transform: scale($value);
}


@mixin display_flex () {
	display: -webkit-flex;
	display: -moz-flex;
	display: -ms-flex;
	display: -o-flex;
	display: flex;
}




@mixin linear_gradient () {
	background: -webkit-linear-gradient(top, #fff 0%, $border_color 100%);
	background: -o-linear-gradient(top, #fff 0%, $border_color 100%);
	background: linear-gradient(top, #fff 0%, $border_color 100%);
}
@mixin linear_gradient_hover () {
	background: -webkit-linear-gradient(top, #fff 0%, $border_color 50%);
	background: -o-linear-gradient(top, #fff 0%, $border_color 50%);
	background: linear-gradient(top, #fff 0%, $border_color 50%);
}

















