
@import 'variables';




// для появления классов в редакторе

/* !!!
.tel_namber {
	font-size: 30px;
	font-family: $font_family_h;
}
*/


@import 'reset';
@import 'mixins';
@import 'bootstrap';



@font-face {
    font-family: 'art';
    src: url('../fonts/art.eot');
    src: url('../fonts/art.eot?#iefix') format('embedded-opentype'),
         url('../fonts/art.woff') format('woff'),
         url('../fonts/art.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
[class*='icon-']:before{
	display: inline-block;
   font-family: 'art';
   font-style: normal;
   font-weight: normal;
   line-height: 1;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale
}


.icon-87405-viber-logo:before{content:'\0041';}
.icon-87422-whatsapp-logo:before{content:'\0042';}
.icon-arrow-pointing-right-in-a-circle:before{content:'\0043';}
.icon-arrow-point-to-right:before{content:'\0044';}
.icon-avatar:before{content:'\0045';}
.icon-chevron-sign-to-right:before{content:'\0046';}
.icon-envelope:before{content:'\0047';}
.icon-eye-blocked:before{content:'\0048';}
.icon-facebook-logo:before{content:'\0049';}
.icon-icon:before{content:'\004a';}
.icon-instagram-logo:before{content:'\004b';}
.icon-keyboard-right-arrow-button:before{content:'\004c';}
.icon-login:before{content:'\004e';}
.icon-login:before{content:'\004f';}
.icon-magnifying-search-lenses-tool:before{content:'\0050';}
.icon-mail:before{content:'\0051';}
.icon-map-placeholder-dark-symbol:before{content:'\0052';}
.icon-music-player-play:before{content:'\0053';}
.icon-ok:before{content:'\0054';}
.icon-online-shopping-cart:before{content:'\0055';}
.icon-play-sign:before{content:'\0056';}
.icon-quotation-mark:before{content:'\0057';}
.icon-quote-button:before{content:'\0058';}
.icon-shopping-basket:before{content:'\0059';}
.icon-shopping-basket:before{content:'\005a';}
.icon-shopping-cart:before{content:'\0061';}
.icon-shopping-purse-icon:before{content:'\0062';}
.icon-skype:before{content:'\0063';}
.icon-skype:before{content:'\0064';}
.icon-smartphone:before{content:'\0065';}
.icon-telegram:before{content:'\0067';}
.icon-user-silhouette:before{content:'\0068';}
.icon-viber:before{content:'\0069';}
.icon-viber-logo:before{content:'\006a';}
.icon-vk:before{content:'\006b';}
.icon-whatsapp:before{content:'\006c';}
.icon-whatsapp-logo:before{content:'\006d';}
.icon-whatsapp-logo:before{content:'\006e';}
.icon-youtube:before{content:'\006f';}
.icon-youtube-logo:before{content:'\0070';}




/* ! таблицы */
.table-theme {
	thead {
		background-color: $color_a_h;
		color: $color_invert;
	}

	tbody {
		tr:nth-child(2n) {
			background-color: rgba($color_a_h, .15);
			color: $color_text;
		}

		tr:nth-child(2n+1) {
			background-color: #FCFCFC;
		}

		tr:hover {
			background-color: rgba($color_a_h, .25) !important;
		}
	}


	td {
		border-right: 1px solid $color_a_h;
	}

	td:last-child {
		border-right: none;
	}
}


.table-striped {
	width: 100%;

	td {
		padding: 10px 15px;
	}
}

.table-shadow {
	box-shadow: 0px 0px 10px 0px rgba(0,0,0,.1);

	thead {
		background-color: rgba($border_color, .5);
	}

	tbody {
		tr {
			border-bottom: 1px solid rgba($border_color, .5);
			background-color: white;
		}

		tr:last-child {
			border-bottom: none;
		}

		td {
			border-right: 1px solid rgba($border_color, .5);
		}

		td:last-child {
			border-right: none;
		}

		tr:hover {
			color: $color_a_h;
		}
	}
}

.table-simple {

	td {
		padding: 10px 15px;
	}

	thead {
		background-color: rgba($border_color, .5);
	}

	tbody {

		td {
			border-right: 1px solid rgba($border_color, .5);
		}

		td:last-child {
			border-right: none;
		}


		tr {
			border-bottom: 1px solid rgba($border_color, .5);
			border-left: 1px solid transparent;
			border-right: 1px solid transparent;
		}

		tr:last-child {
			border-bottom: none;
		}

		tr:hover {
			border-left: 1px solid $color_a_h;
			border-right: 1px solid $color_a_h;
		}
	}
}
/* таблицы ! */


/* ! блоки с цитатами */
blockquote {
	margin-top: 15px;
	margin-bottom: 15px;
	padding-top: 8px;
	padding-bottom: 8px;
	padding-left: 40px;
	font-style: italic;
}

blockquote.blockquote-simple {
	border-left: 5px solid #4ca0f0;
	background-color: lighten($border_color, 30%);
}

blockquote.blockquote-symbol {
	position: relative;
	margin-left: 60px;
	border-left: 5px solid $color_a_h;
	&:before {
		position: absolute;
		content: '\f10d';
		top: 20px;
		left: -60px;
		font-family: "FontAwesome";
		font-size: 30px;
		color: $color_a_h;
	}
}

/* блоки с цитатами ! */










div#scroll_top {
	position: fixed;
	right: -70px;
	bottom: 90px;
	width: 60px;
	height: 60px;
	background: url("../images/go_top.png") no-repeat 0 0;
}
div#scroll_top a {
	display: block;
	width: 60px;
	height: 60px;
	cursor: pointer;
	text-decoration: none;
}



body > div {
	background-color: #fff;
}
p	{
	text-align: justify;
}
div, p {
	font-family: $font_family;
	font-size: $text_size;
	line-height: $line_height;
}
p {
	margin: 0 0 15px;
}
h1, h2, h3, h4, h5 {
	color: $color_a_h;
	text-transform: uppercase;
	font-family: $font_family_h;
}
h1 {
	font-size: $line_height +6;
	padding-top: $line_height;
	padding-bottom: $line_height;
}
h2 {
	font-size: $line_height +4;
	padding-top: $line_height -4;
	padding-bottom: $line_height -4;
	margin: 30px 0;
}
h3 {
	font-size: $line_height +2;
	margin: 20px 0;
}
.modul_h3 {
	@extend h3;
	font-family: $font_family_h;

	&.modul_h3__center {
		text-align: center;
	}

	&.modul_h3__invert {
		color: $color_invert;
	}
}
.modul_h4 {
	@extend h4;
}
a {
	color: $color_a_h;
	transition: all .25s;

	&:hover {
		color: $color_a_hover;
	}
}
p::-moz-selection, h1::-moz-selection, h2::-moz-selection, h3::-moz-selection, h4::-moz-selection, h5::-moz-selection, h6::-moz-selection, a::-moz-selection, strong::-moz-selection, b::-moz-selection, i::-moz-selection, u::-moz-selection, span::-moz-selection, li::-moz-selection  {
	color: #fff;
	background-color: $color_a_hover;
}
p::selection , h1::selection , h2::selection , h3::selection , h4::selection , h5::selection , h6::selection , a::selection , strong::selection , b::selection , i::selection , u::selection , span::selection, li::selection  {
	color: #fff;
	background-color: $color_a_hover;
}


h1 {
	position: relative;
	border-bottom: 2px solid rgba(170, 170, 170, 0.35);
	padding-bottom: 10px;
	margin-bottom: 20px;

	&:after {
		position: absolute;
		content: "";
		background-color: $color_a_h;
		height: 2px;
		width: 100px;
		bottom: -2px;
		left: 0;
	}

}



.swithTem { /* Версия для компьютера */
	display: none;
}










body .pagination  {
	width: 100%;
	border-top: 1px solid $border_color;

	p.counter {
		color: darken($border_color, 20);
		font-size: $text_size - 2;
	}
	ul {
		display: block;

		li {
			display: block;
			float: left;
		}
	}
	span.pagenav {
		opacity: 0.5;
		background: darken($border_color, 15);
		border: 1px solid darken($border_color, 16);

		&:hover {
			background: darken($border_color, 15);
			border: 1px solid darken($border_color, 16);
		}
	}

	li.active a,
	a:hover {
		background-color: $color_a_h;
		color: $color_invert;
		border: 1px solid darken($border_color,10%);
	}



}


.content_bottom {
	margin-top: 30px;
}

div.content div.item-page li, body#tinymce li, ol.nav-tabs {
	padding: 3px 0 3px 25px;
	position: relative;
	border-bottom: 0;

	&::before {
		content: '\f00c';
		font: normal normal normal 14px/1 FontAwesome;
		position: absolute;
		left: 4px;
		top: 7px;
	}

	a {
		border: 0;
		text-decoration: underline;
		padding: 0;

		&:hover  {
			background: none;
			border: 0;
		}
	}
}









/* ! global styles */

.navbar {
	border: none;
}

button {
	i {
		display: none;
	}
}

.readmornews,
input[type="submit"],
input[type="button"],
button,
button.btn,
a.btn,
a.buy {
	padding: 6px 15px;
	border: 1px solid $border_color;
	outline: none;
	background: $color_invert;
	color: $color_text;
	@include border_radius ($btn_border_radius);
	cursor: pointer;

	&:hover {
		border-color: $color_a_h;
		background: $color_a_h;
		text-decoration: none;
		color: $color_invert;
	}
}


.btn.btn-arrow,
.contact_admin .rsform-submit-button {
	position: relative;
	padding: 15px 40px 15px 30px;
	border: 2px solid $border_color;
	background-color: transparent;
	text-transform: uppercase;

	&::before {
		position: absolute;
		top: calc(50% - 7px);
		right: 25px;
		height: 15px;
		font-family: "FontAwesome";
		content: "\f107";
		font-size: 24px;
		transform: rotate(-90deg);
	}

	&:hover,
	&:focus {
		outline: none;
	}
}



/*
.readmore {
	text-align: right;
	margin-top: 20px;
	a {
		font-size: $text_size - 2;
		color: $color_text;
	}
}
*/

.navbar-toggle .icon-bar {
	width: 26px;
	background-color: #fff;
}

.moduletable .modal-dialog {
	margin: 5% auto;
}

.switchery-small {
	margin-right: 8px;
}

.jmapcolumn .collapsable li {
	list-style: disc !important;
}




/* global styles ! */






.no_main .header {
	padding: 0 0 100px;
}

.head2 {
	padding: 35px 0;
	background-color: lighten($border_color, 30%);
	text-align: center;

	.btn {
		margin-top: 20px;
	}
}

header.header {
	background-color: white;
}

header.header.fixed {
	position: fixed;
	width: 100%;
	z-index: 100;
}

.site-inf {
	height: $height_header__site-inf;
}
.site-inf .container {
	position: relative;
}

.l-header__site-inf__container {
	@include display_flex();
	height: $height_header__site-inf;
	justify-content: space-between;
	padding: 0;
}


.korzina a {
	position: fixed;
	z-index: 999;
	left: -2px;
	top: 307px;
	width: 50px;
	height: 246px;
	box-shadow: 1px 1px 7px #565655;
	background: url(/images/korzina_left.png) no-repeat scroll right top #fff;
	text-decoration: none;
	font-size: 0;
}



a.header__logo {
	position: absolute;
	z-index: 23;
	top: -10px;
	left: 0;
	display: block;
	width: 211px;
	height: 188px;
	padding: 20px 10px 10px 10px;
	text-align: center;
	background-color: #fff;
	@include box_shadow (1px 1px 2px 3px rgba(0, 0, 0, 0.1));
	@include transition_mixin(all .2s);

	&:hover {
		top: 0px;
		@include box_shadow (1px 1px 2px 5px rgba(0, 0, 0, 0.2));
	}
}

.header__logo_img {
	max-width: 100%;
    max-height: 100%;
}


div.header_site-name {
	text-transform: uppercase;
	margin: 0;

	p {
		margin: 5px 0;
		color: $color_a_h;
		line-height: 30px;
		font-size: $line_height + 10;
		font-family: $font_family_h;
	}
	p:nth-child(2) {
		color: $color_text;
		font-size: $line_height;
	}
}






address.l-header__contacts {
	@include display_flex();
	justify-content: space-between;
	align-items: center;
	padding-top: 10px;

	a {
		color: $color_text;
		font-style: normal;

		&:hover {
			text-decoration: underline;
		}
	}
	p:nth-child(2) a {
		font-size: $line_height;
		font-family: $font_family_h;
	}

	em {
		font-size: 20px;
		color: $color_a_h;
		opacity: 0.5;
	}

}



section.site-nav {
	position: absolute;
	z-index: 22;
	top: $height_header__site-inf;
	width: 100%;
	height: auto;
	background-color: $color_site_navigation;
	@include box_shadow (0px 4px 5px -2px rgba(0,0,0,0.49));

	.row {
		padding: 0;
		margin: 0;
		width: 100%;
	}
}
nav.header__menu {
	display: flex;

	.mod-nav-main {
		width: 100%;
		height: 100%;

		.navbar-collapse {
			padding: 0;
		}

		.navbar-brand {
			color: $color_invert;
		}
	}

	ul {
		z-index: 10;
	}

	li, li.deeper {
		position: relative;

		a, span.separator {
			display: block;
			padding: 11px 19px;
			color: $color_invert;
			line-height: $line_height;
			transition: .5s;

			&:hover {
				background-color: #4AA0ED;
				/* &:before {
					transform: rotate(90deg);
				} */
			}


		}
		&:hover {

			ul.header__menu-lvl-2 { /* показываем второй уровень меню */
				display: block;
			}

		}
	}

	.active_class {
		background-color: #fff;
		color: $color_a_h;
	}






	li.deeper, li.deeper.active {
		&:before {
			background-size: cover;
			content: '\f054';
			font: normal normal normal 14px/1 FontAwesome;
			display: block;
			height: 15px;
			margin: -7.5px 0 0 0;
			position: absolute;
			right: 5px;
			top: 22px;
			width: 15px;
			color: darken($color_a_h, 5);
			// color: $color_invert;
		}
		a, span.separator {
			padding-right: 25px;
		}

		&:hover:before {
			transform: rotate(90deg); /* во 1м уровне меню поворачиваем стрелку */
		}


		// 2 уровень

		ul {
			position: absolute;
			top: 100%; /* changed 25.07.18 17:11, last value - 42px */
			display: none;
			width: 250px;

			li, li.deeper {
				border-left: 0;
				border-top: 1px solid darken($color_a_h, 20);

				&:hover {
					border-top: 1px solid darken($color_a_h, 25);

					&:before {  /* во 2м уровне не поворачиваем стрелку */
						transform: none;
					}
				}


				a, span.separator  {
					padding-right: 30px;

					border-right: 0;
					border-left: 1px solid  darken($color_a_h, 20);
					border-top: 1px solid  lighten($color_a_h, 10);
					background: darken($color_a_h, 10);
					color: #fff;

					&:hover {
						background: darken($color_a_h, 20);
						border-top: 1px solid  darken($color_a_h, 20);
						color: #fff;
					}
				}

				&:hover {
					ul {
						display: block;
					}
				}

				// 3 уровень

				ul {
					top: 0px;
					right: -250px;
					display: none;

					/* a {
						padding: 8px 10px;
						background: darken($color_a_h, 20);
					} */
				}
			}

		}
	}


	/*
	li.active, li.deeper.active {
		a, span.separator, a:hover, span.separator:hover{
			border-right: 1px solid  darken($color_a_h, 20);
			@extend .active_class;
		}
		ul li.active, ul li.deeper.active {
			a, span.separator, a:hover, span.separator:hover{
				border-top: 1px solid #fff;
				@extend .active_class;
			}
		}
	}
	*/

	li.active > a,
	li.active > span.separator,
	li.active > a:hover,
	li.active > span.separator:hover,

	li.deeper ul li.active > a,
	li.deeper ul li.active > span.separator,

	li.deeper ul li.deeper ul li.active > a,
	li.deeper ul li.deeper ul li.active > span.separator

	{
		background-color: $color_invert !important;
		color: #4AA0ED !important;
	}


}



.element-invisible {
	display: none;
}


.moduletable.mod-search {
	position: relative;
	display: flex;
	width: 30%;
	padding: 0;
	padding-left: 3px;

	.search {
		display: flex;
		width: 100%;
	}

	.form-inline {
		display: flex;
		align-items: center;
		padding: 0;

		input.inputbox {

			margin: 0;
			height: 70%;
			border: none;
			border-bottom: 1px dashed $color_invert;
			border-radius: 0;
			background-color: transparent;
			color: $color_invert;

			&::-webkit-input-placeholder {
				color: white; /* Цвет подсказывающего текста */
			}
		}
	}

	.btn {
		position: relative;
		width: 28px;
		margin-left: -28px;
		padding-left: 0;
		padding-right: 0;
		border: none;
		background-color: transparent;
		color: transparent;


		&:hover,
		&:focus {
			border: none;
			background-color: transparent;
			color: transparent;
			box-shadow: none;
			outline: none;
		}

		&:hover::after {
			top: 1px;
		}

		&::after {
			position: absolute;
			top: 0;
			left: 0;
			content: '\f002';
			font-family: "FontAwesome";
			color: white;
			font-size: $text_size + 8px;
			cursor: pointer;
		}

	}
}



/*  Slider */

.slider-main {
	position: relative;

	/* &::before {
		background: rgba(0, 0, 0, 0.4);
		content: '';
		display: block;
		height: 100%;
		position: absolute;
		width: 100%;
		z-index: 21;
	} */

	.tp-caption {
		max-width: 30% !important;
	}
}

.slider-info {
	color: white;
	position: relative;
	z-index: 100;

	* {
		line-height: 1 !important;
	}

	.slider-title {
		font-size: 55px;
		font-family: $font_family_h;
	}

	.slider-sub-title {
		font-size: 50px;
		font-family: $font_family_h;
	}

	.slider-text {
		font-size: 25px;
	}
}


.moduletable.mod-services {
	display: flex;
	flex-direction: column;
	width: 1170px;
	margin: auto;

	.btn-wrap {
		order: 3;
		margin-top: 35px;
		text-align: center;
	}


	.junewsultra.mod-services {
		display: flex;
		justify-content: space-between;
		border: none;
	}

	.btn-wrap {
		order: 3;
	}

	.jn {
		width: 30%;
		max-width: 395px;
		border: none;
		background-color: transparent;

		.jn-head {
			background-color: transparent;
		}

		.jn-left {
			width: auto;
			float: none;
		}

		.jn-left img {
			padding: 0;
			border: none;
		}

		.jn-right a {
			display: block;
			padding: 20px 15px;
			background-color: $color_a_h;
			color: white;
			text-transform: uppercase;
			font-size: 16px;
			font-family: $font_family_h;

			&:hover {
				text-decoration: none;
			}
		}

		&:hover {
			.jn-right a {
				text-decoration: none;
				background-color: darken($color_a_h, 20%);
			}
		}

	}
}









/* Сторонние стили ------------------------------- */
.tparrows.tp-leftarrow, .tparrows.tp-rightarrow {
	opacity: 1;
	z-index: 22;
}




/* К2 */


div.categoryImage:hover div.image a:after {
	content: '\f00e';
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 40px;
    width: 40px;
    overflow: hidden;
    margin-left: -15px;
    margin-top: -15px;
    font-size: 35px;
    color: #fff;
	text-align: center;
}


div.pg-cv-box:hover a:after {
    content: '\f00e';
    font: normal normal normal 14px/1 FontAwesome;
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    height: 30px;
    width: 30px;
    overflow: hidden;
    margin-left: -15px;
    margin-top: -5px;
	font-size: 24px;
    color: #fff;
}

/* подкатегории товаров */

.itemListCategoriesBlock {
	margin-bottom: 15px;
}

.itemListSubCategories {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	h3 {
		flex: 1 100%;
	}

	.subCategoryContainer {
		width: 32% !important;
	}

	.subCategory {
		margin: 0;
	}
}

.bottom_full_width1 {
	padding: 25px 0;
}

.moduletablek2item {
	margin-bottom: 30px;
}

.moduletablek2item .itemList,
#itemListLeading {
	display: flex;
    flex-wrap: wrap;
	justify-content: space-between;
}

.moduletablek2item .itemList > div,
.itemListView .itemContainer {
	position: relative;
	width: 32% !important;
	min-width: 268px;
	margin-bottom: 20px;
	padding: 0;
	padding-bottom: 80px;
	border: 1px solid $border_color;
	border-radius: $border_radius;


	.catItemImage {
		overflow: hidden;
		max-height: $k2_item_max-height;

		img {
			width: auto;
			max-width: 100%;
			height: $k2_item_max-height;
			@include transition_mixin(all .25s);
		}
	}

	&:hover {
		box-shadow: 0px 3px 10px 1px rgba($color_a_h, .2);
		border-color: $color_a_h;

		.catItemImage img {
			transform: scale(1.1);
		}

		.prod-dop-info {
			border-color: $color_a_h;
		}
	}

	.catItemTitle {
		font-weight: bold;
		text-align: center;

		a {
			color: $color_a_h;
		}
	}

	.catItemView {
		height: 100%;
		padding: 0;
		margin: 0;
	}

	.catItemExtraFields {
		border-top: none;
	}

	.prod-dop-info {
		position: absolute;
		bottom: 0;
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%;
		height: 80px;
		padding: 15px;
		border-top: 1px solid $border_color;
		background-color: lighten($border_color, 30%);
	}

	.catItemIntroText {
		padding: 10px;
		text-align: center;
	}

	.div_money {
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
	}

	.num-of-prod {
		padding: 7px 0px;
		padding-left: 18px;
		border-top-left-radius: $btn_border_radius;
		border-bottom-left-radius: $btn_border_radius;
		border-right: none;
		border-color: $color_a_h;
		outline: none;
		transition: border-radius .15s;

		&:focus {
			border-radius: 0;
		}
	}

	.catItemExtraFieldsLabel {
		display: none;
	}

	div.catItemExtraFields,
	div.genericItemExtraFields {
		padding: 0;
		margin: 0;
	}

	.catItemExtraFieldsValue.tsena {
		font-size: $text_size + 10px;
		font-weight: bold;
		color: $color_a_h;
	}

	.catItemExtraFieldsValue.tsena-mini {
		font-size: $text_size + 4px;
	}

	.typeTextfield {
		margin: 0;
	}

	.buyClick {
		position: relative;
		padding-left: 30px;
		background-color: $color_a_h;
		border: 1px solid $color_a_h;
		color: $color_invert;
		border-top-left-radius: 0;
		border-bottom-left-radius: 0;

		&::before {
			content: "";
			position: absolute;
			left: 10px;
			top: calc(50% - 6px);
			width: 14px;
			height: 12px;
			background-image: url(/images/supermarket-basket.png);
		}
	}

}


.categoryImage {
	&:hover {
		.name {
			background-color: $color_a_hover;
		}
	}
}

.categoryImage .image {
	display: block;
	max-height: 230px;
	overflow: hidden;

	&:hover {
		img {
			transform: scale(1.1);
		}
	}
}

.categoryImage .image img {
	width: 100%;
	@include transition_mixin(all .25s);
}
.categoryImage .name {
	margin-top: 8px;
	height: 50px;
	background-color: $color_a_h;
	margin-left: auto;
	margin-right: auto;
	text-align: center;
}
.categoryImage .name:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle;
}
.categoryImage .name a {
	display: inline-block;
	color: #fff;
	text-decoration: none;
	text-transform: uppercase;
	vertical-align: middle;
	*font-size: 16px;
	font-family: $font_family_h;
}
.categoryImage{
	margin-top: 5px;
}

.categoryImage-collapsed-block {
	padding: 0;
	display: none;
}


div.row > aside, div.row > main {
	@include box_sizing(border-box);
}


.modal-success {
	background-color: rgba(0,0,0,.35);
}


.request-call {
	z-index: 1052;
}

.request-call .modal-dialog {
	width: 420px;
}


.modal-content {
	border-radius: 0;
}


.modal-header {
	@include display_flex();
	justify-content: space-between;
	align-items: center;
	height: 70px;
	border-bottom: 1px solid #e5e5e5;
}

.request-call__header-btn-close {
	position: absolute;
	top: 20px;
	right: 20px;
}


.modal-body {
	@include display_flex();
	justify-content: center;
}

.korzin_image img {
	max-width: 100%;
}

.modal .modul_h4.modal-title {
	display: flex;
    align-items: center;
    padding: 0 20px;
}

.request-call  .formContainer {
	width: 300px;
}

.request-call__fullname,
.request-call__telephone {
	margin: 0 0 10px;
}

.request-call__fullname .rsform-input-box,
.request-call__telephone .rsform-input-box {
	width: 100%;
	border-radius: $border_radius;
	outline-color: $re-form__active-element;
}

.request-call__captcha {
	position: relative;
	clear: both;
	justify-content: space-between;
	height: 30px;

	img {
		float: left;
		height: 30px;
		width: 100px;
	}

	.rsform-captcha-box {
		float: right;
		height: 30px;
		margin: 0;
		text-align: center;
	}

	a[href="javascript:void(0)"] {
		background: $re-form__active-element url(/templates/art-web.ru/images/icons/reload.png) no-repeat center;
		height: 30px;
		overflow: hidden;
		position: absolute;
		right: 148px;
		text-indent: -9999px;
		width: 30px;
	}
}

.itemAgree {
	margin-top: 25px;

	label {
		font-weight: normal;
	}
}


.request-call__submit .rsform-submit-button {
    display: block;
	margin: auto;
}


.modal-footer {
	@include display_flex();
	justify-content: space-between;
	flex-direction: column;
	align-items: center;

	> div,
	button {
		margin: 5px 0;
	}
}


.request-call__or-call-this-number {
	font-size: 14px;
	line-height: 34px;
}
.request-call__footer-btn-close {
	background: $re-form__active-element;
	border-radius: 0;

}


.table_bottom {
	padding: 15px 0;
	background-color: lighten($border_color, 30%);
}


/* ! Отзывы */


body .carousel-inner>.active,
body .carousel-inner>.next,
body .carousel-inner>.prev {
	display: flex;
}

#phocaguestbook_comments .cd-testimonials-wrapper {
	margin-bottom: 5px;
	padding-bottom: 10px;
}

#phocaguestbook_comments {
	.flex-prev:focus,
	.flex-next:focus,
	.flex-prev:hover,
	.flex-next:hover {
		outline: none;
	}
}

#phocaguestbook_comments .btn-wrap {
	text-align: center;
}

#phocaguestbook_comments .cd-testimonials-item-info {
	position: relative;
	min-height: 400px;
	/* width: 30%;
	margin: 0 1.5%; */
	padding: 60px 30px;
	border: 1px solid $border_color;
	border-radius: $border_radius;

	&::before,
	&::after {
		content: "";
		position: absolute;
		display: flex;
		justify-content: center;
		width: 40px;
		height: 40px;
		border: 1px solid $border_color;
		background-color: white;
		color: $border_color;
		font-size: 65px;
		line-height: normal;
	}

	&::before {
		content: "\201C";
		left: -15px;
		top: 10px;
	}

	&::after {
		content: "\201D";
		right: -15px;
		bottom: 10px;
	}

	&:hover {
		border-color: $color_a_h;
		box-shadow: 0px 3px 10px 1px rgba($color_a_h, 0.2);

		&::before,
		&::after {
			border-color: $color_a_h;
			color: $color_a_h;
			box-shadow: 0px 3px 10px 1px rgba($color_a_h, 0.2);
		}

	}
}


.comment-item-text {
	margin-bottom: 30px;
	text-align: center;
	font-style: italic;
	font-size: $text_size;
}

.comment-item-username {
	text-align: center;
	color: $border_color;
	font-size: $text_size + 2;
}

.comment-item-date {
	text-align: center;
	color: $border_color;
}

#phocaguestbook_comments .flex-direction-nav {

	li {
		display: flex;
		align-items: center;
		width: auto;
	}

	a {
		position: relative;
		width: 50px;
		height: 50px;
		border: 1px solid $border_color;
		border-radius: 50%;
		background-color: white;


		&:hover {
			border-color: $color_a_h;

			&:before,
			&:after {
				background-color: $color_a_h;
			}
		}

		&:first-child {
			margin-left: 25px;
		}

		&:last-child {
			margin-right: 25px;
		}
	}
}


/* Отзывы ! */




/* ! статьи с иконками на главной (JUNewsUltra) */

.moduletable.mod-materials-icons-in-index-page {
	margin: 30px 0;
	text-align: center;

	.junewsultra {
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		overflow: visible;
	}

	.jn {
		width: 350px;
		padding: 30px;
		border: none;
		border: 1px solid transparent;
		background-color: rgba($border_color, .1);

		&:hover {
			border: 1px solid green;
			box-shadow: 0px 3px 10px 1px rgba(0, 128, 0, 0.2);
		}

		.jn-head {
			background-color: transparent;
		}

		.jn-left,
		.jn-right {
			width: auto;
			float: none;
		}

		.jn-left {
			width: 80px;
			height: 80px;
			margin: auto;
			padding: 10px;
			border-radius: 50%;
			background-color: $color_a_h;

			a {
				display: flex;
				width: 100%;
				height: 100%;
			}

			img {
				display: block;
				width: 48px;
				height: 48px;
				margin: auto;
				padding: 0;
				border: none;
			}
		}

		.jn-intro {
			font-size: 16px;
			line-height: 1.5;
		}

		.m_h4 a {
			display: block;
			margin-top: 22px;
			margin-bottom: 15px;

			font-size: 20px;
			color: $color_text;
			font-weight: bold;

			&:hover {
				text-decoration: none;
				color: $color_text;
			}
		}

		.jn-more {
			margin-top: 15px;
			text-align: center;
		}

		.btn {
			background-color: $color_a_h;
			color: #fff;

			&:hover {
				text-decoration: none;
				background-color: #fff;
				color:  $color_a_h;
			}
		}

	}
}

/* статьи с иконками на главной (JUNewsUltra) ! */


/* ! статьи на главной (JUNewsUltra  на всю ширину) */

.moduletable.mod-materials-in-index-page {
	margin: 0px 0;

	.jn {
		position: relative;
		display: flex;
		margin: 0;
		border: none;

		.jn-left {
			float: none;
			width: auto;

			height: 100%;

			a {
				display: block;
				height: 100%;
			}

			img {
				display: block;
				height: 100%;
				padding: 0;
				border: none;

				object-fit: cover; // поддерживается не всеми браузерами
			}
		}

		.jn-head {
			width: 50%;
		}

		.jn-intro {
			display: flex;
			flex-direction: column;

			width: 50%;
			padding: 70px 50px;
			padding-bottom: 40px;

			.jn-right {
				order: -1;
				text-align: center;

				a {
					display: block;
					margin-bottom: 20px;
					font-size: 24px;

					&:hover {
						text-decoration: none;
					}
				}
			}
		}

		.jn-more {
			margin-top: 20px;
			text-align: center;

			a {
				border: 1px solid $border_color;

				&:hover {
					border-color: transparent;
					text-decoration: none;
				}
			}
		}


		&:nth-child(2n+1) {
			.jn-intro {
				order: -1;
			}

			.jn-more {
				bottom: 15px;
				left: 15px;
			}
		}


		&:nth-child(2n) {
			.jn-more {
				bottom: 15px;
				right: 15px;
			}
		}

	}


}


/* статьи на главной () ! */


/* ! Преимущества */
.benefits {
	padding: 0 0 50px 0;
}

.benefits .benefits-cart {
	padding: 0;
	margin-bottom: 25px;

	&:hover {
		.svg-container {
			@include box_shadow (0px 3px 10px 1px rgba($color_a_h, 0.2));
		}

		svg {
			fill: $color_header-m;
		}

	}
}

.svg-container {
	position: relative;
	display: flex;
	width: 100%;
	height: 170px;
	margin-bottom: 10px;

	img {
		margin: auto;
	}
}

.benefit-title {
	text-align: center;
}
/* Преимущества ! */



/* ! модуль Версия для слабовидящих */

.mod-a11y {
	position: fixed;
	z-index: 100;
	right: 0;
	top: 150px;
	width: 50px;

	.module_special_visually {
		width: 205px;
	}

	input[name='type_version'] + span {
		position: relative;
	}

	input[name='type_version'] + span::before,
	.params::before {
		content: "";
		position: absolute;
		top: -2px;
		right: 100%;

		display: flex;
		justify-content: center;
		align-items: center;

		width: 50px;
		height: 50px;

		border-radius: 3px 0 0 3px;
		border-bottom: 3px solid darken($color_a_h, 10%);

		background: $color_a_h url(../images/eye-blocked.png) no-repeat center;
		background-size: 65%;

		transition: all .25s;
	}

	input[name='type_version'] + span:hover::before {
		border-color: $color_a_h;
	}

	.buttons label {
		margin: 0;
	}


	.params {
		position: fixed;
		z-index: 1100;
		right: 0;
		top: 0;

		display: flex;
		flex-direction: column;
		width: 250px;
		height: 100%;
		padding-left: 10px;

		transition: all .35s;
		transform: translateX(250px);

		#params-scrolling {
			display: flex;
			flex-direction: column;

			overflow-y: auto;
			height: 100%;

			padding-top: 40px;
			padding-bottom: 40px;

			.buttons .handle_module {
				display: block;
			}

		}

		input[name='type_version'] + span::before {
			display: none;
		}

		&::before {
			top: 150px;
		}

		&:hover {
			transform: translateX(0px);
		}

	}

	.title.handle_module {
		width: 100%;
	}


	.module_special_visually #special_visually {

		.buttons-a11y-activating {
			margin: 0;
		}

		.buttons-a11y-activating label {
			margin-left: 100%;
			border-color: $color_a_h;
			border-right: 0 !important;
			transition: all .25s;
		}

		.buttons-a11y-activating label:hover {
			margin-left: 0;
		}

		.buttons-a11y-activating label .button_text {
			display: flex;
			align-items: center;
			padding: 8px 0;
			padding-left: 20px;
			height: 46px;
			line-height: 1;
			text-transform: uppercase;
			text-align: left;
		}


		.params .param .title {
			margin-right: 0;
		}

		label.handle_module {
			margin: 0;
			margin-bottom: 5px;
		}

		.buttons-original label.handle_module {
			margin: 0;
		}

		label#btn-A11Y-deactivated {
			display: none;
			order: -1;
		}

	}






}



/* модуль Версия для слабовидящих ! */


div.comfort-rooms {
	@include display_flex();
	background: fixed url(/templates/art-web.ru/images/backgrounds/bg_akcia.jpg) no-repeat center;
	justify-content: center;
	height: 680px;
	position: relative;
	align-items: center;

	&::before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: rgba(0,0,0,0.3);
	}
}
.l-comfort-rooms {
	position: relative;
	z-index: 1;
	@include display_flex();
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 400px;
	width: 400px;
	@include border_radius (50%);
	background-color: #fff;

	p {
		padding: 5px 20px;
		text-align: center;
	}
}
.comfort-rooms__btn-more {
	padding: 0 30px;
}



/* иконки

https://fontawesome.ru/all-icons/
http://localhost:3000/plugins/system/ytshortcodes/assets/css/font-awesome.min.css

*/

@font-face {
	font-family:'FontAwesome';
	src:url('../fonts/fontawesome/fontawesome-webfont.eot?v=4.6.3');
	src:url('../fonts/fontawesome/fontawesome-webfont.eot?#iefix&v=4.6.3') format('embedded-opentype'),
		url('../fonts/fontawesome/fontawesome-webfont.woff2?v=4.6.3') format('woff2'),
		url('../fonts/fontawesome/fontawesome-webfont.woff?v=4.6.3') format('woff'),
		url('../fonts/fontawesome/fontawesome-webfont.ttf?v=4.6.3') format('truetype'),
		url('../fonts/fontawesome/fontawesome-webfont.svg?v=4.6.3#fontawesomeregular') format('svg');
	font-weight:normal;font-style:normal
}

div.soc a.fa {
	font-size: 18px;
	padding: 10px;
	display: block;
	float: left;
}


a.a_pdf:before,
a.a_zip:before,
a.a_doc:before,
a.a_xls:before,
a.a_mp3:before,
a.a_mp4:before,
a.a_you:before
{
	font: normal normal normal 14px/1 FontAwesome;
	padding: 0 3px;
}
a.a_pdf:before {
	content: '\f1c1';
}
a.a_zip:before {
	content: '\f1c6';
}
a.a_doc:before {
	content: '\f1c2';
}
a.a_xls:before {
	content: '\f1c3';
}
a.a_mp3:before, a.a_mp4:before {
	content: '\f1c7';
}
a.a_you:before {
	content: '\f1c8';
}









.b-telephones {
	width: 180px;
	padding: 0 0 0 45px;
	background: url(/templates/art-web.ru/images/icons/footer_icon.png) no-repeat 7px 5px;
}

.telephones__href {
	display: block;
	color: $inactive-hrefs;
	font-size: 16px;
	font-weight: 400;

	&:hover {
		color: $color_a_hover;
	}
}




.b-email {
	@include display_flex();
	align-items: center;
	width: 180px;
	height: 40px;
	padding: 0 0 0 45px;
	background: url(/templates/art-web.ru/images/icons/footer_icon.png) no-repeat 7px -41px;
}




.b-address {
	@include display_flex();
	align-items: center;
	width: 180px;
	height: 40px;
	margin: 0 auto;
	padding: 0 0 0 45px;
	background: url(/templates/art-web.ru/images/icons/footer_icon.png) no-repeat 8px -88px;
}

.address__text {
	margin: 0;
	color: $inactive-hrefs;
	font-size: 16px;
	text-align: left;
}




.footer {
	background-color: #cfcfd1;
	// height: $height_footer;
}


.footer_2 {
	.row {
		display: flex;
	}
}





.copyright__text {
	margin: 0;
	color: #fff;
	font-size: 12px;
	text-align: left;
	line-height: 14px;
}


.pers-inf, .copyright {
	@include display_flex();
	flex-direction: column;
	// height: $height_footer;
	justify-content: center;
	text-align: center;

	&__ref {
		color: #fff;
		font-size: 12px;
		line-height: 14px;
	}

	a {
		color: $color_invert;

		a:hover {
			color: inherit;
		}
	}

}

.pers-inf {
	text-align: right;

	div {
		line-height: 12px;
	}
}


div.art_web_copyright {
	position: relative;
	height: $height_footer;
	background: url(/templates/art-web.ru/images/author_art-web.png) no-repeat 100% -9%;

	&:hover {
		background-position: 100% 109%;
	}

	> div {
		position: absolute;
		top: 50%;
		right: 110px;
		width: 222px;
		height: 30px;
		margin: -15px 0 0 0;
		text-align: right;

	}

	a {
		display: block;
		color: #fff;
		font-size: 12px;
		line-height: 15px;
	}
}








.blog .pull-left {
	margin: 0 15px 15px 0;

	img {
		width: 300px;
	}

	p img {
		display: none;
	}
}

.blog [class^="leading"] {
	margin: 0 15px 15px 0;

	img {
		width: 300px;
	}

	p img {
		display: none;
	}
}





/* Phoca Gallery Categories View */
.pg-csv-box {
	float: left;
	margin: 5px;
	padding: 10px;
	background-color: #fff;
	@include box_shadow (1px 1px 2px 1px rgba(120, 120, 120, 0.5));
	@include border_radius (2px);

	&:hover {
		background-color: #fafafa;
	}

}
.pg-csv-name {
	margin-top: 5px;
	padding-left: 20px;
	background: url(../../images/icon-folder-small.png) 0 1px no-repeat;
	text-align: left;
}
.pg-csv-count {
	font-size: xx-small;
	color: #ccc;
}
.pg-csv-rate {
	font-size: xx-small;
	color: #ccc;
}
.pg-csv-descbox {
	font-size: x-small;
}

.pg-csv-tags {
	text-align: right;
	font-size: x-small;
}


/* Фотогаллерея */
div.pg-cv-box {
	position: relative;
	float: left;
	margin: 10px;
	padding: 0px;
	overflow: hidden;
	height: 250px;

	img {
		@include transition_mixin (all  0.3s ease);
	}

	&:hover {

		img {
			@include transform (1.1);
			opacity: 0.8;
		}

		a.slimbox:after {
			content: '\f00e';
			font: normal normal normal 14px/1 FontAwesome;
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			width: 30px;
			height: 30px;
			overflow: hidden;
			margin-left: -15px;
			margin-top: -5px;
			font-size: $text_size + 10;
		}
	}

}


.pg-cv-name {
	margin-top: 5px;
	text-align: left;
	font-size: x-small;
}
.pg-cv-folder {
	margin-top: 5px;
	text-align: left;
	padding-left: 20px;
}
.pg-cv-descbox {
	font-size: x-small;
}
.pg-cv-tags {
	text-align: right;
	font-size: x-small;
}
.pg-cv-comment-img-box {
	margin: 5px 0px;
	padding: 5px;
	@include border_radius (2px);
	background-color: #fdfdfd;
	color: #ccc;
	font-size: small;
}
.pg-cv-comment-img-box-avatar {
	position: relative;
	float: left;
	margin-right: 5px;
}
.pg-cv-comment-img-box-item {
	margin: 0px 0px 0px 0px;
	padding: 5px 0px;
	border-bottom: 1px solid #fff;
}
.pg-cv-add-comment-img {
	display: none;
}
.pg-cv-vote-img-result {
	float:left;
	margin: 0;
	margin-left: 5px;
	padding: 0;
}
.pg-cv-paginaton {
	margin-bottom: 20px;
}
/* Phoca Gallery Categories View in Category View */
.pg-cvcsv {
	margin-top: 10px;
	margin-bottom: 10px;
	padding-bottom: 5px;
	border-bottom: 1px solid #e8e8e8;
	border-top: 1px solid #e8e8e8;
	clear: both;
}
.pg-cvcsv-name {
	text-align: left;
	margin-top: 5px;
	padding-left: 20px;
}
.pg-box-parentfolder-cv, .pg-cvcsv-back {
	text-align: left;
	margin-top: 5px;
	padding-left: 20px;
}
.pg-cvcsv-count  {
	font-size: xx-small;
	color: #ccc;
}
/* Phoca Gallery Popup Windows - Detail View */
.pg-dv-desc {
	height: 16px;
	color: #333;
	font-size: small;
}
.pg-dv-desc-lighbox {
	color: #fff;
	background-color: #000;
}
.pg-dv-multibox-left {
	background-color: #000;
}
.pg-dv-multibox-right {
	background-color: #fff !important;
}
/* Modal Box */
 #sbox-window {
	padding: 2px !important; /* Border Width*/
	background-color: #6b6b6b !important; /*Border Color*/
}
#sbox-overlay {
	background-color: #000 !important; /* Background Color*/
}
#sbox-content {
	background-color: #fff !important;
}






/* Phoca Gallery General */
.ph-hr {
	margin-top:10px;
	margin-bottom:10px;
	border-bottom:1px solid #e8e8e8;
	clear:both;
}
.pg-tb-m5 {
	margin: 10px 0px;
}
dt h3 {
	margin: 2px auto 3px auto;
	padding: 0px auto;
	font-size: small;
	font-weight: normal;
}
.pg-icon-detail {
	text-align:right;
	margin: 0px;
	padding: 0px;
	margin-top: 2px
}
/* Phoca Overlib */
.ph-ovrl1 {
	padding: 5px;
}
.ph-ovrl2 {
	margin: 0px;
	padding: 0px;
}
.bgPhocaClass{
	background-color:#fdfdfd;
	filter:alpha(opacity=100);
	opacity: 1;
	z-index: 1000;
}
.fgPhocaClass{
	background-color:#fdfdfd;
	filter: alpha(opacity=100);
	opacity: 1;
	-moz-opacity: 1;
	z-index: 1000;
}
.fontPhocaClass{
	color:#000;
	z-index:1001;
}
.capfontPhocaClass, .capfontclosePhocaClass{
	color:#000;
	font-weight: bold;
	z-index: 1001;
}
/* Obsolete Parameters */
.pg-cats-box-float2 {
	float:		left;
	padding: 	5px;
}
div.pg-cats-box {
	display: block;
	border-image: 2px solid #fff;
	margin: 5px;
	padding: 8px;
	border: 1px solid $border_color;
	@include border_radius (2px);
	text-align: center;
	@include transition_mixin (all  0.5s ease);
	@include box_shadow (1px 1px 2px 1px rgba(120, 120, 120, 0.1));


	&:hover {
		@include box_shadow (1px 1px 2px 2px rgba(120, 120, 120, 0.3));

		img {
			@include transform (1.1);
			// @include transition_mixin (all  0.5s ease);
			opacity: 0.8;
		}
	}

	div.pg-cats-box-img {
		overflow: hidden;
		height: 250px;

		img {
			@include transition_mixin (all  0.3s ease);
		}
	}

}
.pg-field-desc2, .pg-field-table2 {
	margin-left: 10px;
	margin-top: 5px;
}
.pg-field-desc,
.pg-field-table {
	margin-left: 10px;
}
.pg-field {
	margin: 10px;
	padding: 10px;
	background-color: #fff;
	@include box_shadow (1px 1px 2px 1px rgba(120, 120, 120, 0.5));
	@include border_radius (2px);
}
.pg-legend {
	font-weight: bold;
	font-size: large;
}
div.pg-cats-name.small, div.pg-cats-name {
	padding-top: 5px;
	text-align: center;

	a {
		display: block;
		font-size: $text_size;
	}

	span {
		opacity:0.7;
	}
}


ul.breadcrumb li {
	font-size: $text_size - 2;
	opacity: 0.5;

	&:hover {
		opacity: 1;
	}
	span.divider {
		opacity: 1;
	}
}


div.moduletablejunews_mod {

	.junews_mod {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}

	div.junews_h {
		@extend .modul_h3;
	}

	.junewsultra .jn .jn-left {
		width: 100%;
		float: none;
	}

	.jn {
		border: none;
		width: 48%;


		.jn-head {
			background-color: transparent;
		}

		.m_h4 a {
			color: $color_a_h;
			text-transform: uppercase;
			font-weight: bold;
		}

		.jn-left img {
			display: block;
			max-width: 100%;
			width: auto;
			margin-bottom: 15px;
			padding: 0;
			border: none;
		}

		.jn-more {
			.readmore {
				@extend .btn;
			}

			text-align: right;
		}

		&:first-child {
			width: 100%;
			margin-bottom: 15px;
		}

	}

}




.input-group-addon { /* убираем иконки на странице отзывов */
	display: none;
}


div.subCategory {
	border: 1px solid $border_color;
	border-radius: $border_radius;
	background-color: lighten($border_color,30%);
}


.l-categories-images {
	position: relative;
	background: url(/templates/art-web.ru/images/backgrounds/bg_katalog.jpg) no-repeat center;
	background-attachment: fixed;

	&::before {
		content: '';
		position: absolute;
		display: block;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.4);
	}
}

.categ-img {
	padding: 50px 0 0 0;
}


.l-categ-img__btn-all-categ {
	padding: 20px 0 40px 0;
	text-align: center;
}
.categ-img__btn-all {
	position: relative;
	z-index: 1;
	display: inline-block;
	padding-right: 40px;
	padding: 0 20px;
	border: 1px solid #fff;
	@include border_radius (20px);
	color: $color_invert;
	line-height: 40px;
	text-align: center;
	text-transform: uppercase;
	transition: padding 0.5s;

	.fa {
		position: absolute;
		right: 10px;
		top: 50%;
		display: none;
		margin: -14px 0 0 0;
		font-size: 25px;
		opacity: 0;
		transition-delay: .7s;
	}

	&:hover {
		padding: 0 50px 0 20px;
		background-color: #fff;
		color: $color_header-m;
		text-decoration: none;

		.fa {
			display: block;
			opacity: 1;
		}
	}
}

div.сategory-img {
	width: calc(33% - 12px);
	margin: 15px 7px;
	padding: 0;
	overflow: hidden;
	position: relative;

	div.image {
		height: 243px;
		overflow: hidden;
	}

	div.name {
		background-color: rgba(0,0,0, 0.45);
		bottom: 0px;
		font-weight: 700;
		position: absolute;
		width: 100%;
	}


	&:hover {

		img {
			@include transform (1.1);
			@include transition_mixin (all  0.3s ease);
			opacity: 0.8;
		}

		div.name {
			background-color: rgba(0,0,0, 0.55);
		}

		div.image a:after {
			content: '\f00e';
			font: normal normal normal 14px/1 FontAwesome;
			position: absolute;
			top: 50%;
			left: 50%;
			display: block;
			margin-left: -15px;
			margin-top: -15px;
			width: 40px;
			height: 40px;
			overflow: hidden;
			font-size: 35px;
			color: #fff;
			text-align: center;
		}
	}



}



// редактор


body#tinymce, body#tinymce p {
	background-color: #fff;
	color: #000;

	td {
		vertical-align: top;
	}
}










/* формы */

// select
// textarea
// input[type="text"]
// input[type="password"]
// input[type="datetime"]
// input[type="datetime-local"]
// input[type="date"]
// input[type="month"]
// input[type="time"]
// input[type="week"]
// input[type="number"]
// input[type="email"]
// input[type="url"]
// input[type="search"]
// input[type="tel"]
// input[type="color"]


form {
	padding: 10px 0;
}

input, textarea, select {
	margin: 5px 0;
	padding: 11px 12px 10px;
	border: 1px solid $border_color;
	@include border_radius($border_radius);
	font-family: $font_family;
	font-size: $text_size;
	color:  $color_text;

	&:focus {
		border: 1px solid darken($border_color, 20);
		color: darken($color_text, 20);
	}
}
input#Submit {
	@extend a.btn;
	position: relative;
	margin-top: 15px;
	padding: 10px 15px;
	text-transform: uppercase;

	&:hover {
		top: -2px;
	}
}


div.formControls {
	padding: 10px 0;

	p {
		margin: 0;
	}
}

div.rsform-block-captcha img {
	padding: 0 10px;
}


div.moduletable.rs-form-make-order {
	position: absolute;
	z-index: 21;
	right: calc( 50% - 585px);
	top: 200px;
	max-width: 410px;
	padding: 20px;
	overflow: hidden;
	background-color: rgba(0, 0, 0, .45);

	form {
		padding: 0;
	}

	div.formControls {
		color: #fff;
	}

	input#Submit {
		margin-top: 0;
	}

	h2 {
		margin: 0 0 20px 0;
		color: $color_invert;
		text-align: left;
		text-transform: uppercase;
	}
	.formControlLabel {
		display: none;
	}

	input, select {
		@include box_shadow (none);
	}

	input[type="text"], select {
		margin: 0;
		padding: 15px;
		width: 100%;
		border: none;
		border-radius: 0;
		background-color: #000;
		color: #B5B4B4;
		font-size: 14px;

		&:focus {
			color: #fff;
			@include box_shadow (inset 0 1px 1px $color_a_h, 0 0 8px $color_a_h);
			opacity: 1;
		}
	}

	select {
		width: 370px;
	}

	input#Submit {
		@include border_radius (0);
	}

	div.rsform-block-arrivaldate, div.rsform-block-departuredate {
		width: 50%;
		height: 77px;
		float: left;
		position: relative;

		input {
			width: 143px;
		}
	}

	div.rsform-block-departuredate > div {
		position: relative;
	}


	.formControls .formBody .rsform-calendar-button {
		position: absolute;
		left: 150px;
		top: 13px;
		width: 20px;
		height: 43px;
		margin: 0 0 9px 0;
		padding: 0;
		border: none;
		border-radius: 0;
		color: #fff;
		text-align: center;
		background-color: #4C4C4D;

		&:hover {
			background-color: #E4E4E4;
			cursor: pointer;
		}
	}

	label {
		font-weight: normal;
	}

	p.formDescription {
		margin: 0;
	}
	div.rsform-block-captcha {
		background-color: #fff;
	}

	.itemAgree {
		a {
			color: $color_invert;
			text-decoration: underline;
		}

		a:hover {
			text-decoration: none;
		}
	}
}


div.rsform-block.rsform-block-captcha {
	float: left;
	width: 370px;
	overflow: hidden;

	div.formControls {
		padding: 0;
	}

	input.rsform-captcha-box {
		width: 149px;
	}

	a {
		display:block;
		float: right;
		padding: 15px 17px 0 0;

		&:hover {
			text-decoration: underline;
		}
	}

}

main#content {
	padding-bottom: 25px;
}





.formNoError {
	display: none;
}



/* ! левая колонка */


.sbat { /* иконка стрелочки для открытия вложенных пунктов в меню */
	position: absolute;
	top: 1px;
    right: 0;
	z-index: 1;

    display: flex;
    align-items: center;
	justify-content: center;


    width: 50px;
    height: 50px;
	cursor: pointer;

	&.up {
		&::after {
			margin-top: 0;
			margin-left: 0;
			transform: rotate(90deg);
		}
	}

	&::after {
		content: '\f054';
		font: normal normal normal 14px/1 FontAwesome;
		display: block;
		color: $border_color;
		transition: all .25s;
	}

	&:hover {
		background-color: lighten($border_color, 20%);

		&::after {
			color: $color_a_h;
		}
	}
}



.l-categories-images {
	padding: 0;
}

body .column-wrap {
	padding-left: 0;
}

.column-wrap {
	.navbar-collapse {
		padding: 0;
	}

	#phocaguestbook_comments {
		border: 1px solid $border_color;

		.custom {
			padding: 15px;
		}
	}

	.modul_h3__comments {
		border: 1px solid $border_color;
		border-bottom: 0;
	}
}

.column-wrap .left_column > div,
.column-wrap .right_column > div  { // модули
	margin-bottom: 20px;
}

.column-wrap .modul_h3 { // заголовки
	margin: 0;
	padding: 22px 0;
	padding-left: 10px;
	border-radius: $border_radius;
	background-color: lighten($border_color, 30%);
}


.moduletable.mod-news-column { // новости в левой колонке
	margin-bottom: 10px;
	padding-bottom: 10px;
	border: 1px solid $border_color;
	border-radius: $border_radius;

	.jn {
		margin-bottom: 8px;
		margin: 10px 15px;
		padding: 10px 0;
		border-bottom: 1px solid $border_color;

		&:last-child {
			border-bottom: none;
		}

		.jn-intro {
			font-size: $text_size - 2px;
		}

		.jn-right {
			text-transform: uppercase;
			font-weight: bold;
		}

		.jn-more {
			margin-top: 6px;
			text-align: right;
		}
	}
}


div.moduletable_leftmenu { // меню в левой колонке
	border: 1px solid $border_color;
	@include border_radius($border_radius);
	margin-bottom: 20px;

	ul { /* первый уровень меню */
		padding: 0;

		li {
			display: block;
			margin: 0;
			padding: 0;
			list-style: none;

			a, span.separator {
				padding: 15px 10px;
				color: $color_text;
				transition: 0s;
				display: block;
				text-decoration: none;
				cursor: pointer;
				border-bottom: 1px solid $border_color;
				background-color: transparent;

				&:hover {
					text-decoration: underline;
				}
			}



			&:last-child {
				a, span.separator {
					border-bottom: none;
				}
			}

			ul {
				a, span.separator {
					border-bottom: 1px solid $border_color !important;
					background-color: lighten($border_color, 25%) !important;
				}

				ul {
					a, span.separator {
						background-color: lighten($border_color, 10%) !important;
					}
				}
			}


		}
		li:first-child {
			a, span.separator {
				border-top: 1px solid $border_color;
			}
		}
		/*
		li:first-child {
			a, span.separator {
				@include border_radius($border_radius $border_radius 0 0);
				border-top: 1px solid $border_color;
			}
		}
		*/




		li	{
			position: relative;

			/* &.deeper:before {
				background-size: cover;
				content: '\f054';
				font: normal normal normal 14px/1 FontAwesome;
				display: block;
				height: 15px;
				margin: -7.5px 0 0 0;
				position: absolute;
				right: 5px;
				top: 22px;
				width: 15px;
				color: $color_a_h;
				transform: rotate(90deg);
			} */

			&:hover {
				/* ul { // показываем второй уровень меню
					display: block;
				} */

				&.deeper:before {
					// transform: rotate(90deg);
					color: $color_a_hover;
				}
			}

			ul { /* второй уровень меню */
				display: none;

				li {

					&:hover {
						/* ul.header__menu-lvl-3 { // показываем третий уровень меню
							display: block ;
						} */
					}

					a {
						font-size: 13px;
						padding: 15px 10px 15px 20px;
						background: #e1e1e1;

						&::before {
							content: "\f00c";
							margin-right: 4px;
							font-family: "FontAwesome";
						}
					}


					ul.header__menu-lvl-3 { /* третий уровень меню */
						display: none ;

						li {

							a {
								padding: 10px 10px 10px 40px;

								&::before {
									font-size: $text_size - 2px;
								}
							}
						}
					}



					/* li.active, li:hover {
						a, span.separator {
							text-decoration: underline;
						}
					} */
				}
			}
		}
	}


	.active { /* активные пункты меню */
		> a,
		> span.separator {
			text-decoration: underline;
		}
	}
}

.mod-calendar { // календарь событий
	min-height: 304px;

	div.k2CalendarBlock {
		height: auto;
	}

	table.calendar tr td {
		padding: 11px;
	}

	table.calendar tr td {
		border: 1px solid rgba($border_color, .2);
	}

	table.calendar tr td.calendarToday {
		background-color: $color_a_h;
	}

}

.mod-auth { // модуль авторизации
	border: 1px solid $border_color;

	.modul_h3 {
		border-bottom: 1px solid $border_color;
	}

	form {
		padding: 15px;
	}
}


/* левая колонка ! */


section.bottom_full_width3 {
	position: relative;
	padding-top: 15px;
	background-image: url("/images/site/bg_form.jpg");
	background-position: bottom;
	background-attachment: fixed;

	.contact_admin {
		.modul_h3 {
			color: $color_invert;
		}

		.itemAgree {
			color: white;
			a {
				text-decoration: underline;
				color: white;


				&:hover {
					text-decoration: none;
				}
			}
		}
	}

	&::before {
		content: "";
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color_a_h, 0);
	}

	.row {
		position: relative;
	}

}


div.contact_admin {
	@extend .container;

	.formContainer {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
	}

	div.formControlLabel {
		display: none;
	}
	div.rsform-block {
		width: 32%;
		margin: 0 .65%;
	}

	div.rsform-block.rsform-block-comment {
		width: 100%;

		textarea {
			width: 100%;
		}
	}

	.rsform-block-submit {
		text-align: center;
		flex: 1 100%;

		.btn {
			display: inline-block;
		}
	}

	input[type="text"] {
		width: 100%;
	}

	.rsform-block-agree .formBody {
		display: flex;
	}

	input,
	textarea,
	select {
		border: none;
	}

	.btn {
		border-color: $color_invert;
		color: $color_invert;

		&:hover {
			border-color: $color_a_h;
		}
	}

}



div#toTop {
	position: fixed;
	right: -70px;
	bottom: 90px;
	width: 60px;
	height: 60px;
	background: url("../images/go_top.png") no-repeat 0 0;
}
div#toTop a{
	display: block;
	width: 60px;
	height: 60px;
	cursor: pointer;
	text-decoration: none;
}





//ul#menu_fotter

.navbar-nav > li > span.separator  {
	padding: 15px;
	display: block;
}

/*
.full-contact-inf {
	.mod-footer-contacts {
		display: flex;
		align-items: center;
		justify-content: space-around;

		.social-icons {
			display: flex;
			justify-content: space-around;

			.soc-icons__fa {
				color: $color_a_h;

				&:hover {
					color: $color_a_hover;
				}
			}
		}

		.contacts-wrap {
			margin-left: 10px;
		}
	}
}
*/

.full-contact-inf {
	padding: 30px 0;
}

.mod-footer-contacts {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	padding: 15px;

	.contacts-wrap {
		display: flex;
		flex-direction: column;
		font-size: 16px;
		color: $border_color;

		a {
			font-size: $text_size + 2;
			color: $border_color;

			&:hover {
				color: $color_a_h;
			}
		}

		.tel,
		.mail,
		.address {
			position: relative;

			display: flex;
			flex-direction: column;
			justify-content: center;

			min-height: 32px;
			padding-left: 45px;
			margin-bottom: 15px;

			font-size: 16px;

			&:before {
				content: '';
				position: absolute;
				left: 0;
				width: 35px;
				height: 36px;
				background-image: url("/images/site/footer_icon.png");
				background-repeat: no-repeat;
				background-position: 50% 0px;
			}
		}

		.tel {

			.tel_namber {
				margin: 6px 0;
			}

			a {
				display: block;
				line-height: 1;
			}

			&:before {
				top: calc(50% - 17.5px);
			}
		}

		.mail::before {
			background-position: 50% -53px;
		}

		.address::before {
			background-position: 50% -107px;
		}
	}


	.social-icons {
		display: flex;
		justify-content: space-around;
		width: 80%;
		margin: auto;
		margin-top: 30px;
		padding: 10px 25px 0;
		border-top: 1px solid $color_a_h;
		font-size: $text_size + 12px;


		a {
			color: $border_color;

			&:hover {
				color: $color_a_h;
			}
		}

	}

}


.mod-footer-map {
	min-height: 600px;
}

.footer_1 {
	.moduletable.soc {
		a:hover {
			text-decoration: none;
		}
	}
}



@media (max-width: 1200px) {

	header {

		section .container {
			width: 100%;
		}

		section.site-inf {
			height: auto;
		}

		.header__menu {
			width: 100%;
  			margin-left: 0;
		}

		.header__logo {
			position: static;
			margin: auto;
			box-shadow: none;
		}

		.l-header__site-inf__container {
			width: 100%;
			margin-left: 0;
		}

		section.site-nav {
			position: static;

			.container,
			.row {
				padding: 0;
			}
		}
	}


	div.moduletable.rs-form-make-order  {
		position: static;
		width: 50%;
		margin: 15px auto;
	}


	.moduletable.mod-services {
		width: 100%;

		.junewsultra.mod-services {
			flex-wrap: wrap;
			justify-content: space-around;
		}

		.jn {
			width: 50%;
		}
	}

	/* ! статьи с иконками на главной (JUNewsUltra) */
	.moduletable.mod-materials-icons-in-index-page {

		.junewsultra {
			justify-content: center;
		}

		.jn {
			margin: 15px calc(25% - 175px);
		}
	}
	/* статьи с иконками на главной (JUNewsUltra) ! */



	div.moduletablejunews_mod { /* блок новостей  */

		.junews_mod {
			flex-direction: column;
		}

		.jn {
			width: 100%;

			img {
				display: block;
				margin: auto;
				max-width: 100%;
			}
		}
	}

	/* товары */

	.moduletablek2item .itemList > div,
	.itemListView .itemContainer {


	}
}

@media (max-width: 1000px) {

	.slider-main {
		display: none;
	}

	div.moduletable.rs-form-make-order  {
		width: 80%;
	}

	#phocagallery-categories-detail .pg-cats-box-float,
	.itemListSubCategories .subCategoryContainer,
	#itemListLeading .itemContainer,
	.moduletablek2item .itemList > div {
		width: 48% !important;
	}

	.junewsultra.mod-services {
		flex-direction: column;
		align-items: center;

		.jn {
			width: 100%;
			margin-bottom: 15px;
		}
	}

	.moduletable.mod-materials-in-index-page {
		.jn {
			flex-direction: column;

			.jn-head {
				width: 100%;
			}

			.jn-intro {
				order: -1;
				width: 100%;
			}
		}
	}

	.footer_1 {
		.row {
			display: flex;
			flex-direction: column;
		}

		.moduletable {
			width: 100%;
		}

		.soc {
			display: flex;
   			justify-content: center;
		}
	}


	.footer_2 {
		padding: 15px 0;

		.row {
			display: flex;
			flex-direction: column;

			> div {
				margin: auto;
				margin-bottom: 10px;
			}
		}
		.copyright .copyright__text {
			text-align: center;
		}

		.art_web_copyright {
			padding-bottom: 45px;
			background-image: none;

			&:after {
				content: '';
				display: block;
				height: 60px;
				background: url(/templates/art-web.ru/images/author_art-web.png) 50% 0% no-repeat;
			}

			> div {
				text-align: center;
				position: static;
				width: auto;
				margin: auto;
			}

		}
	}

}

@media (max-width: 768px) {

	.moduletable.mod-search {
		width: 100%;
	}

	div.moduletable.rs-form-make-order  {
		width: 100%;
	}

	.no_main .header {
		padding: 0;
	}

	.header {
		display: flex;
		flex-direction: column;
		margin-bottom: 15px;

		.site-inf {
			height: auto;
			order: 2;
		}

		.header__logo {
			position: static;
			margin: auto;
			height: auto;
			box-shadow: none;
		}

		.site-nav {
			top: auto;
			order: 1;
		}

		.l-header__site-inf__container {
			// display: block;
			flex-direction: column;
			height: auto;

			.header_site-name {
				width: 100%;
				text-align: center;

				p {
					text-align: center;
				}
			}

			.l-header__contacts {
				width: 100%;
				flex-direction: column;
			}
		}

		.header__menu {
			flex-direction: column;

			.mod-nav-main {
				order: 2;
			}

			.search.mod-search {
				order: 1;
				justify-content: center;
				width: 100%;
				margin-top: 5px;

				input.inputbox {
					position: static;
					display: block;
				}

				.navbar-collapse {
					padding-left: 10px;
				}
			}

		}
	}

	.column-wrap {
		padding: 0;

		.moduletable_leftmenu {
			.modul_h3 {
				display: none;
			}
		}
	}




	/* меню в моб версии */

	.column-wrap .moduletable_leftmenu { /* отключаем меню в левой колонке в моб версии */
		display: none;
	}

	body.no-overflow { // убирает прокрутку при активном главном меню
		overflow: hidden;
	}

	.mod-nav-main .navbar-collapse,
	nav.header__menu li.deeper ul,
	nav.header__menu li.deeper.active ul { /* главное меню в мобильной версии */
		display: block;
		position: fixed;
		z-index: 100;
		top: 0;
		bottom: 0;
		left: -100%;
		width: calc(100% - 70px);
		border: none;
		background-color: $color_invert;

		transition: all .25s;
	}


	.mod-nav-main .menu_main {
		margin: 0;
		max-width: 100%;
	}

	.mod-nav-main a,
	.mod-nav-main .separator {
		font-size: $text_size + 2;
		color: darken($border_color, 20%) !important;
		border-left: none !important;
	}

	.mod-nav-main a {
		color: $color_a_h;
	}

	.mod-nav-main li a {
		color: $color_a_h;
		background-color: $color_invert !important;
		border: none !important;
	}

	.mod-nav-main a,
	.mod-nav-main span.separator {
		padding: 15px !important;
	}

	.mod-nav-main li {
		border-top: 0.5px solid lighten($border_color, 20%) !important;
		border-bottom: 0.5px solid lighten($border_color, 20%) !important;
		border-left: none !important;
		border-right: none !important;
	}



	.nav.header__menu li:hover,
	nav.header__menu li.deeper:hover {
		border-right: none;
	}

	nav.header__menu li.deeper ul,
	nav.header__menu li.deeper.active ul {
		display: block;
		transition: all 0.2s;
	}

	.show-menu {
		overflow-y: scroll;
	}

	.mod-nav-main .navbar-collapse.show-menu,
	nav.header__menu li.deeper ul.show-menu,
	nav.header__menu li.deeper.active ul.show-menu {
		left: 0;
		height: auto;
	}


	.mod-nav-main li.deeper {
		position: relative;
	}

	.mod-nav-main li.current a {
		background-color:  $color_invert;
		color: $color_a_h;
	}

	.btn-next {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		border: none;
		border-radius: 0;
		background-color: lighten($border_color, 25%);
	}

	.btn-prev {
		width: 100%;
		padding: 11px 13px;
		border: none;
		border-radius: 0;
		text-align: left;
		font-size: $text_size;

		&:hover,
		&:focus {
			color: $color_text;
			background-color: transparent;
		}
	}

	.header__submenu li:first-child {
		margin-top: 35px;
	}

	.close-menu-btn-wrap {
		position: fixed;
		top: 0;
		bottom: 0;
		right: -100%;
		width: 70px;
		height: 100vh;
		background-color: rgba(0,0,0,.8);
	}

	.close-menu-btn-wrap.show {
		right: 0;
	}

	.close-menu-btn {
		position: relative;
		width: 45px;
		height: 45px;
		border: none;
		border-radius: 0;
		background-color: $color_a_h;

		&::before,
		&::after {
			content: "";
			position: absolute;
			top: calc(50%);
			left: 0;
			width: 100%;
			height: 1px;
			background-color: $color_invert;
		}

		&::before {
			transform: rotate(45deg);
		}

		&::after {
			transform: rotate(-45deg);
		}
	}



	.left_column-wrap {
		padding-left: 15px;
	}

	.formContainer {
		display: flex;
		flex-wrap: wrap;

		.rsform-block {
			width: 100% !important;

			input,
			select,
			textarea {
				display: block;
				margin: auto;
				width: 100% !important;

				&.rsform-calendar-box {
					width: 85% !important;
				}

				&.rsform-calendar-button {
					left: auto !important;
					right: 0;
					width: 10% !important;
				}
			}

			.rsform-block-submit .formBody,
			.rsform-block-agree .formBody {
				text-align: center;
			}

			.rsform-block-captcha .formBody div {
				display: block;
				margin: auto;
			}
		}
	}


	.junewsultra.mod-services {
		width: 100%;

		.jn {
			max-width: auto;
			width: 90%;
		}
	}

	/* ! статьи с иконками на главной (JUNewsUltra) */
	.moduletable.mod-materials-icons-in-index-page {
		.jn {
			margin: 15px auto;
		}
	}
	/* статьи с иконками на главной (JUNewsUltra) ! */

	main#content {
		img {
			max-width: 100%;
			margin: auto;
			display: block;
			float: none !important;
			height: auto;
		}
	}


	#phocagallery-categories-detail .pg-cats-box-float,
	.itemListSubCategories .subCategoryContainer,
	#itemListLeading .itemContainer,
	.moduletablek2item .itemList > div {
		width: 96% !important;
		margin-left: auto;
		margin-right: auto;
	}

	.categoryImage .image {
		max-height: 100%;
	}

	.junewsultra.junews_mod {
		.jn {
			width: 100%;
			margin-bottom: 12px;

			.jn-intro img {
				display: block;
				width: 100%;
				height: auto;
				float: none !important;
			}
		}
	}

	address.how-get-in-touch {
		position: static;
		border-radius: 0;
	}

	.mod-carousel-of-logos {
		padding: 0 45px;
	}

	.mod-footer-map {
		height: 450px;
		overflow: hidden;
	}

	.footer_1 {
		.moduletable.soc {
			display: flex;
			justify-content: center;
		}
	}


}

@media (max-width: 500px) {


	/* ! footer */

	.full-contact-inf {

		.mod-footer-contacts {
			flex-direction: column;

			> div {
				margin-bottom: 18px;
			}

			.social-icons {
				margin-top: 8px;
			}

			.contacts-wrap {
				width: 100%;
			}
		}

		.logo__img {
			display: block;
			margin: auto;
		}

	}
	/* footer ! */
}


/* main top block */

body.main .top_full_width2{
	max-width: 1920px;
	min-height: 680px;
	display:flex;
	align-items: flex-end;

	margin-top:21px;

	background-position-y:97%;
	background-position-x: center;
	background-image: url("/images/site/bg_top.jpg");
	background-size: cover;
	background-repeat:no-repeat;
}

body.main .top_full_width2 > .container, .moduletable.main-top {
	height:100%;
}

.moduletable.main-top {
	display:flex;
	justify-content: flex-end;
}

.top_full_width2 .right-block {
	width: 601px;
	height: 574px;

	padding-left:39px;
	padding-right:15px;
	padding-top:52px;

	background: -webkit-linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
background: -moz-linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
background: -o-linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
background: -ms-linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
background: linear-gradient(0deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
}

.moduletable.main-top h2 {
	margin:0;

	font-family: Cuprum;
color:
#161719;
font-size: 25px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
text-align: center;
}

.moduletable.main-top h1 {

	padding-top:15px;
	padding-bottom:15px;
	margin-bottom:10px;

font-family: Cuprum;
color:
#15337b;
font-size: 29.22px;/* Приближение из-за подстановки шрифтов */
font-weight: 700;
line-height: 17.6px;/* Приближение из-за подстановки шрифтов */
text-align: center;
border:0;
}

.moduletable.main-top p {
	margin:0 0 30px 0;

	font-family: Arial;
color:
#313131;
font-size: 14px;
font-weight: 400;
line-height: 20px;
text-align: left;
}

.moduletable.main-top h1:after {
	display:none;
}

.moduletable.main-top p.sub-h {
	margin-bottom:20px;

	text-transform:uppercase;
	font-family: Cuprum;
color:
#15337b;
font-size: 20px;/* Приближение из-за подстановки шрифтов */
font-weight: 700;
text-align: center;
}

.moduletable.main-top a.btn {
	width:104px;

	margin:0 auto;
	margin-top:-10px;
	display:block;
}


body.main section.main {
	display:none;
}

body.main .bottom_full_width1 {
	display:none;
}

/* Block on main page */

.moduletable.mod-materials-in-index-page .jn:nth-child(2n+1) .jn-more {
	position:absolute;

	left:25% !important;
	margin-left:-52px;

}

.moduletable.mod-materials-in-index-page .jn:nth-child(2n) .jn-more {
	position:absolute;

	right:25% !important;
	margin-right:-52px;

}

@media screen and (min-width:1500px) {
	.moduletable.mod-materials-in-index-page .jn:nth-child(2n+1) .jn-more {
	top:80%;
}

.moduletable.mod-materials-in-index-page .jn:nth-child(2n) .jn-more {
	top:80%;
}
}

/* Footer */

.mod-footer-contacts .contacts-wrap .tel .tel_namber,
 .mod-footer-contacts .contacts-wrap .mail {
	width:270px;
	font-family: Arial;
	color: #191919;
	font-size: 16px;
	font-weight: 400;
}

.mod-footer-contacts .contacts-wrap .address {
	font-size:14px;
	width:270px;
	font-family: Arial;
	color: #191919;
}

.mod-footer-contacts .contacts-wrap .tel .tel_namber {
	margin-top:1px;
	margin-bottom:1px;
}

.h-ft {
	width:240px;

	margin-bottom:20px;

	font-family: Cuprum;
color:
#15337b;
font-size: 29.22px;/* Приближение из-за подстановки шрифтов */
font-weight: 700;
line-height: 32px;/* Приближение из-за подстановки шрифтов */
text-align: left;
text-transform: uppercase;
}


	.mod-footer-contacts .contacts-wrap .tel:before {
		top:1.5px;
	}

 .mod-footer-contacts .contacts-wrap .address:before {
 	top:-4px;
 }

 .mod-footer-contacts .contacts-wrap .mail:before {
 	top:-6px;
 }

 .footer {
 	background-color:#0D2F7B;
 }

 .copyright__text  {
color:
#9eb7f4;
font-size: 11px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
line-height: 12px;/* Приближение из-за подстановки шрифтов */
text-align: left;
 }

.pers-inf a, .copyright a {
color:
#9eb7f4;
font-size: 11px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
line-height: 12px;/* Приближение из-за подстановки шрифтов */
text-align: right;
 }

 div.art_web_copyright a {
 	color:
#9eb7f4;
font-size: 11px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
line-height: 12px;/* Приближение из-за подстановки шрифтов */
text-align: right;
 }

 section.full-contact-inf > div.container > div.row {
 	display:flex;
 	align-items: center;
 }

 /* header */


 .l-header__site-inf__container, .header__menu.navbar {
 	width:80%;
 	margin-left:20%;
 }

 .header__menu.navbar  {
 	padding:0;
 }

 address.l-header__contacts {
 	display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
 }

  address.l-header__contacts p {
 	display:flex;
 	flex-direction: column-reverse;
 	align-items: flex-end;
 }

 address.l-header__contacts p a {
 	position: relative;
 }

 address.l-header__contacts p a:nth-child(1):before {
 	background-image: url("/images/site/mail.png");
	content:"";

	left:-30px;
	top:5px;

	position:absolute;
	width:18px;
	height:14px;
 }

.mod-materials-in-index-page h3 {
	font-family: Cuprum;
color:
#4ca0f0;
font-size: 29px;/* Приближение из-за подстановки шрифтов */
font-weight: 700;
text-align: center;
}

.moduletable.benefits .modul_h3.modul_h3__center {
	padding-top:30px;

	font-family: Cuprum;
color:
#15337b;
font-size: 28px;/* Приближение из-за подстановки шрифтов */
font-weight: 700;
line-height: 10px;/* Приближение из-за подстановки шрифтов */
text-align: center;
}

.benefit-title {
	font-family: Arial;
color:
#191919;
font-size: 16px;
font-weight: 400;
text-align: center;
}

section.bottom_full_width3 .contact_admin .modul_h3 {
	font-family: Cuprum;
color:
#ffffff;
font-size: 27.79px;/* Приближение из-за подстановки шрифтов */
line-height: 10px;/* Приближение из-за подстановки шрифтов */
text-align: center;
margin-bottom:0;
margin-top:36px;
}

.contact-wrap {
	  display: flex;
    position: relative;
    width: 660px;
    flex-wrap: wrap;
}

.rsform-block.rsform-block-captcha {
    width: 50% !important;
}

section.bottom_full_width3 .contact_admin .itemAgree {
	    position: absolute;
    top: 10px !important;
    width: 50%;
    right: 0;
    margin-top:0;
}

div.contact_admin .rsform-block-submit {
	margin-top:10px !important;
}

.moduletable.contact_admin p {
	font-family: Arial;
color:
#ffffff;
font-size: 14px;
font-weight: 400;
line-height: 19px;
text-align: center;

margin:10px !important;
}

div.contact_admin .formContainer {
	margin-top:20px;
}

div.formControls {
	padding:0;
}

.moduletable.mod-search .form-inline input.inputbox,
.moduletable.mod-search .search, .moduletable.mod-search {
	width:130px;
}

.moduletable.l-header__contacts {
	justify-content: center;
}
address.l-header__contacts p {
	margin-bottom:0;
}

div.header_site-name {
	display:flex;
	flex-direction: column;
	justify-content: center;
}

div.header_site-name p:nth-child(1){
	font-family: Cuprum;
color:
#15337b;
font-size: 29.22px;/* Приближение из-за подстановки шрифтов */
font-weight: 700;
line-height: 17.6px;/* Приближение из-за подстановки шрифтов */
text-align: left;
}

div.header_site-name p:nth-child(2){
	font-family: Cuprum;
color:
#010101;
font-size: 20px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
line-height: 14px;/* Приближение из-за подстановки шрифтов */
text-align: left;
}

address.l-header__contacts p a {
	font-family: Cuprum;
color:
#000000;
font-size: 16px;/* Приближение из-за подстановки шрифтов */
font-weight: 400;
line-height: 22px;/* Приближение из-за подстановки шрифтов */
text-align: left;
}

address.l-header__contacts p a:nth-child(1) {
	text-decoration: underline;
}

 .nav > li > a:focus {
	background-color:transparent;
}

.korzina {
	display:none;
}

.readmore {
	text-align: right;
}

body.view-article div.item-page {
display:flex;
}

body.view-article div.item-page .pull-left{
	padding-right: 20px;
}

/* Category */


body.view-category .blog blockquote.blockquote-simple{
margin-left: 320px;
}

/* Media Queries */

@media screen and (max-width: 1500px) {

.moduletable.mod-materials-in-index-page .jn:nth-child(2n) .jn-more {
	bottom:30px !important;
}

.moduletable.mod-materials-in-index-page .jn:nth-child(2n) .jn-more {
	bottom:15px !important;
}
}

@media screen and (max-width: 1200px) {

	.moduletable.mod-map > ymaps {
		width:100% !important;
	}

	.l-header__site-inf__container, .header__menu.navbar {
		margin-left:0%;
		width:100%;
		padding-left:20px;
		padding-right:20px;
	}

body.main .top_full_width2 {
	margin-top:0;
}
}

@media screen and (max-width: 1000px) {
.moduletable.mod-materials-in-index-page .jn .jn-more {
	position:static !important;
	margin:0px 0 20px 0 !important;
	order:-1;
	}
.moduletable.mod-materials-in-index-page .jn-right {
		display:none;
	}

	.moduletable.mod-materials-in-index-page .jn .jn-intro {
		padding-top:20px;
		padding-bottom:10px;
	}

	div.art_web_copyright a, .pers-inf div {
		text-align: center;
	}
div.header_site-name {
	width:100%;
}

}

@media screen and (max-width: 991px) {
	.full-contact-inf .container .row {
		display:flex;
		flex-direction:column;
	}

	.moduletable.mod-map, .mod-footer-contacts {
		width:100%;
	}

	body.view-article div.item-page {
		flex-direction:column;
		align-items: center;
	}

	}

@media screen and (max-width: 800px) {
	.blog .pull-left {
		width:100%;
		text-align: center;
	}

	.blog h3 {
		text-align: center;
	}

	body.view-category .blog blockquote.blockquote-simple {
		margin-left:0;
	}
}

@media screen and (max-width: 780px) {
nav.header__menu li a, nav.header__menu li span.separator,
 nav.header__menu li.deeper a,
  nav.header__menu li.deeper span.separator {
padding: 11px 17px;
}
}


@media screen and (max-width: 768px) {
.moduletable.mod-search {
	width:100%;
	margin:0 auto;
}

.header .header__menu .search.mod-search input.inputbox {
	width:180px;
}

.full-contact-inf {
	padding-bottom: 0px;
}

.moduletable.mod-map {
	padding:0;
}

.header .l-header__site-inf__container .l-header__contacts {
	align-items: center;
}
}

	@media screen and (max-width: 680px) {
	section.bottom_full_width3 .contact_admin .itemAgree {
		position:static;
		width:100%;
	}

	div.contact_admin div.rsform-block {
		margin:0 auto;
		width:100% !important;
		text-align: center;
	}
	.formControls > .formBody > div > div{
		margin:0 auto;
	}
	}

	@media screen and (max-width: 600px) {

		.moduletable.main-top .right-block {
			width:100%;
		}

		.top_full_width2 .right-block {
			min-height:574px;
			height:auto;
		}

		div.header_site-name p:nth-child(1), .moduletable.main-top h1 {
			line-height:28px;
		}

	}


		@media screen and (max-width: 500px) {
			.mod-footer-contacts .contacts-wrap .address,
			.mod-footer-contacts .contacts-wrap .tel,
			 .mod-footer-contacts .contacts-wrap .mail {
				width:210px !important;
			}

			.full-contact-inf .mod-footer-contacts .contacts-wrap {
				align-items: center;
			}
		}


		@media screen and (max-width: 400px) {
			div.header_site-name p:nth-child(2) {
				line-height:22px;
			}

			.mod-footer-contacts .contacts-wrap .tel .tel_namber {
				width:100%;
				display:block;
			}
		}