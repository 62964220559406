

$color_a_h: #15337B;								 /* цвет заголовков и ссылок на всем сайте  */
$color_a_hover: #2176C1;		   /* цвет hover ссылок  */
$color_site_navigation: rgba($color_a_h, 1);	   /* полоса меню с прозрачностью или без */
$color_text: #333;								 /* цвет текста */
$color_invert: #ffffff;							 /* второй цвет */
$border_color: #aaa;							 /*  цвет всех рамок на сайте */
$border_radius: 0px;							   /*  радиус рамки на сайте */
$inactive-hrefs: #000000;							 /* неактивные ссылки  */
$color_header-m: red;							 /* цвет заголовков и ссылок в шапке  */

/*
$svg_background: green;
$svg_background-hover: green;
*/

$rs-form__text-color: #312d13;
$re-form__active-element: #ffa700;


$text_size: 14px;									/* размер шрифта  */
$line_height: 20px;									/* высотра строк  */
$height_header__menu_point: 40px;					/* размеры блоко м- пункты меню  */
$height_rs-form__input: 44px;						/* высота input в рс форме */
$height_footer: 70px;								/* высота футера подвал */
$height_header__site-inf: 80px;						/* высота верхней шапки до меню  */


$font_family:  Arial, sans-serif;
$font_family_h: 'Cuprum', sans-serif;


$k2_item_max-height: 220px;                          /*  максимальная высота картинок в карточках k2 */
$btn_border_radius: 25px;                           /* радиус у кнопкок */

